import { navigate } from "gatsby";
import * as React from "react";
import { useCallback } from "react";
import { useJobOutput } from "../../../hooks/api/job/useJobOutput";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { Shimmer, StyledImage } from "./JobImage.styles";

interface IProps {
    readonly imageId: string | null;
    readonly jobId: string;
    readonly height: number;
    readonly width: number;
    readonly canOpen?: boolean;
}

export const JobImage: React.FC<IProps> = ({ imageId, jobId, width, height, canOpen = true }) => {
    const jobOutputData = useJobOutput(jobId, imageId, true, { width, height });

    const onClick = useCallback(() => {
        if (imageId === null) {
            return;
        }
        if (canOpen === false) {
            return;
        }
        navigate(HeadbotRoute.ViewJobImage(jobId, imageId));
    }, [imageId, canOpen]);

    if (jobOutputData.isLoading || jobId === null || jobOutputData.data === null) {
        return <Shimmer width={width} height={height} />;
    }
    return (
        <StyledImage
            width={width}
            height={height}
            src={jobOutputData.data}
            onClick={onClick}
            isLoading={false}
            useCursorPointer={canOpen}
        />
    );
};
