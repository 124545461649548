import { AccountStatus } from "@headbot/library";
import { Redirect } from "@reach/router";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { useHasMounted } from "../../../hooks/useHasMounted";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";

interface IProps {
    readonly route?: string;
    readonly fallback?: JSX.Element;
}

export const WithoutAuth: React.FC<React.PropsWithChildren<IProps>> = ({ children, route, fallback }) => {
    const { tokenData, isLoading } = useAuth();
    const [t] = useTranslation();
    const hasMounted = useHasMounted();

    if (isLoading) {
        <>{t("constants.loading")}</>;
    }
    if (!hasMounted) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (tokenData === null) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (fallback === undefined) {
        return <React.Fragment>{<Redirect from="*" to={route ?? HeadbotRoute.Dashboard} noThrow />}</React.Fragment>;
    }
    if (tokenData.status === AccountStatus.NotVerified) {
        return <Redirect from="*" to={HeadbotRoute.Activate} noThrow />;
    }
    return <React.Fragment>{fallback}</React.Fragment>;
};
