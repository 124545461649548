import { Dimensions } from "./../../layout/GlobalStyles/Dimensions";
import styled from "styled-components";

export const GalleryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    gap: ${Dimensions.GridUnit * 2}px;
`;
