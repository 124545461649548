import { Link } from "gatsby";
import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Font } from "../../layout/GlobalStyles/Font";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";

enum Viewport {
    Small = 479,
    Medium = 639,
    Large = 1023,
    ExtraLarge = 1365,
    ExtraExtraLarge = 1919,
}

interface IStyleProps {
    readonly isExpanded?: boolean;
    readonly hideOnMobile?: boolean;
    readonly isDarkTheme?: boolean;
}

export const Root = styled("div")<IStyleProps>`
    width: 100%;
    position: fixed;
    top: 0px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    backdrop-filter: blur(8px);
    z-index: 100;
    color: ${({ isDarkTheme }) => (isDarkTheme ? Color.White100 : Color.Neutral100)};
`;

export const Container = styled.div`
    display: flex;
    align-items: stretch;
    width: 1500px;
    max-width: 95%;
    margin: 0px auto;

    @media screen and (max-width: ${Viewport.Large}px) {
        flex-direction: column;
        max-width: 100%;
    }
`;

export const MobileMenu = styled.div`
    display: none;
    align-items: center;
    padding: 4px 0px;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        justify-content: space-between;
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        z-index: 10;
    }
`;

export const Logo = styled(Link)`
    font-family: ${Font.Poppins};
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: ${Color.Foreground};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Dimensions.GridUnit}px;
    font-weight: 700;
`;

export const StyledBurger = styled.button`
    display: none;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 1.5rem;
        height: 1.5rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0px;
        z-index: 5;
    }
`;

export const BurgerLine = styled("div")<IStyleProps>`
    display: none;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: inline;
        width: 1.5rem;
        height: 0.2rem;
        background: ${({ isDarkTheme }) => (isDarkTheme ? Color.White100 : Color.Neutral100)};
        border-radius: 10px;
        position: relative;
    }
`;

export const Menu = styled("div")<IStyleProps>`
    display: flex;
    width: 100%;
    grid-template-columns: repeat(3, minmax(10px, max-content));
    align-items: center;
    padding: 4px 0px;
    justify-content: space-between;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: ${({ isExpanded }) => (isExpanded ? "flex" : "none")};
        flex-direction: column;
        padding-bottom: 32px;
    }
`;

export const MainMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MenuBlock = styled("div")<IStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "grid")};
        justify-items: center;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    height: 22px;

    @media screen and (max-width: ${Viewport.Large}px) {
        padding-top: 16px;
    }
`;

export const MenuLink = styled(Link)`
    font-family: ${Font.SpaceGrotesk};
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: inherit;
    display: grid;
    padding: 8px 16px;
    border-radius: 4px;
    transition: color 150ms ease;

    :hover {
        opacity: 80%;
    }

    &.active {
        color: ${Color.Primary100};
    }
`;

export const MenuLinkStyledOverride = styled.a`
    font-family: ${Font.SpaceGrotesk};
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: inherit;
    display: block;
    padding: 8px 16px;
    border-radius: 4px;
    transition: color 150ms ease;

    :hover {
        opacity: 80%;
    }

    &.active {
        color: ${Color.Primary100};
    }
`;

export const MenuButtonLink = styled(Link)`
    font-family: ${Font.SpaceGrotesk};
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    background-color: ${Color.Primary100};
    display: inline-block;
    margin-left: 10px;
    line-height: 1.32;
    padding: 8px 16px;
    color: ${Color.Neutral0};
    transition: background-color 100ms ease;
    box-shadow: 0px 0px 8px ${Color.Primary50};

    :hover {
        background-color: ${Color.Primary110};
    }
`;
