import styled from "styled-components";
import logo from "./logo-icon.svg";

export const NavLogo = styled.div`
    background-image: url(${logo});
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    background-size: fit;
    background-position: center center;
    display: inline-block;
`;
