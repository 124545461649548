import * as React from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../services/Constants/Constants";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { NavLogo } from "../LogoSymbol/NavLogo";
import { RouterLink } from "../RouterLink/RouterLink";
import { TelemetryLink } from "../TelemetryLink/TelemetryLink";
import { Connect } from "./Connect";
import { BottomWrapper, Container, LinksWrapper, LogoWrapper, MenuLinkStyledOverride, Root, Subtitle, Wrapper } from "./Footer.styles";

interface IProps {
    readonly isDarkTheme?: boolean;
}

export const Footer: React.FC<React.PropsWithChildren<IProps>> = ({ isDarkTheme = false }) => {
    const [t] = useTranslation();

    return (
        <Root isDarkTheme={isDarkTheme}>
            <Container>
                <Wrapper>
                    <div>
                        <LogoWrapper>
                            <RouterLink href={HeadbotRoute.Home} aria-label={t("footer.home")}>
                                <NavLogo />
                            </RouterLink>
                            <Subtitle>{t("footer.tagLine")}</Subtitle>
                        </LogoWrapper>
                    </div>
                    <LinksWrapper>
                        <Connect />
                    </LinksWrapper>
                </Wrapper>
                <BottomWrapper>
                    {`${t("footer.copyright")} | `}
                    <TelemetryLink href={Constants.MailToLink} styledOverride={MenuLinkStyledOverride}>
                        {t("footer.contact")}
                    </TelemetryLink>
                    {` | `}
                    <TelemetryLink href={Constants.PrivacyLink} target="_blank" styledOverride={MenuLinkStyledOverride}>
                        {t("footer.privacy")}
                    </TelemetryLink>
                </BottomWrapper>
            </Container>
        </Root>
    );
};
