import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { MenuButtonLink, MenuItem } from "./NavigationHeader.styles";

export const PortalMenuItem: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <MenuItem>
            <MenuButtonLink to={HeadbotRoute.Dashboard}>{t("navigation.dashboard")} →</MenuButtonLink>
        </MenuItem>
    );
};
