import { TelemetryEvent } from "@headbot/library";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Gallery } from "../../molecules/Gallery/Gallery";
import { useEffect } from "react";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";

interface IProps {
    readonly jobId: string;
}

export const HeadjobPage: React.FC<React.PropsWithChildren<IProps>> = ({ jobId }) => {
    const [t] = useTranslation();
    const description = t("publicGalleryPage.description");
    const title = t("publicGalleryPage.title");
    useEffect(() => {
        telemetry.Log(TelemetryEvent.GalleryView, jobId);
    }, []);
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.HeadJobPage(jobId)}>
            <Gallery jobId={jobId} />
        </ScrollablePageTemplate>
    );
};
