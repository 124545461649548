import * as React from "react";
import { ScrollableBodyRoot } from "../../layout/BodyRoot/BodyRoot";
import { Footer } from "../../molecules/Footer/Footer";
import { NavigationHeader } from "../../molecules/NavigationHeader/NavigationHeader";
import { SEO } from "../../molecules/SEO/SEO";

interface IProps {
    readonly title: string;
    readonly description: string;
    readonly canonical: string;
}

export const ScrollablePageTemplate: React.FC<React.PropsWithChildren<IProps>> = ({ children, title, description, canonical }) => {
    return (
        <React.Fragment>
            <SEO title={title} description={description} relativeCanonicalUrl={canonical} />
            <ScrollableBodyRoot>{children}</ScrollableBodyRoot>
            <NavigationHeader />
            <Footer />
        </React.Fragment>
    );
};
