import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { WithoutAuth } from "../Auth/WithoutAuth";
import { NavLogo } from "../LogoSymbol/NavLogo";
import {
    BurgerLine,
    Container,
    Logo,
    MainMenuWrapper,
    Menu,
    MenuBlock,
    MenuButtonLink,
    MenuItem,
    MenuLink,
    MobileMenu,
    Root,
    StyledBurger,
} from "./NavigationHeader.styles";
import { PortalMenuItem } from "./PortalMenuItem";

interface IProps {
    readonly isDarkTheme?: boolean;
}

export const NavigationHeader: React.FC<IProps> = ({ isDarkTheme = false }) => {
    const [t] = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const onBurgerClicked = useCallback(() => {
        const newState = !isExpanded;
        setIsExpanded(newState);
    }, [isExpanded]);

    return (
        <Root isDarkTheme={isDarkTheme}>
            <Container>
                <MobileMenu>
                    <NavLogo />
                    <StyledBurger onClick={onBurgerClicked}>
                        <BurgerLine isDarkTheme={isDarkTheme} />
                        <BurgerLine isDarkTheme={isDarkTheme} />
                        <BurgerLine isDarkTheme={isDarkTheme} />
                    </StyledBurger>
                </MobileMenu>
                <Menu isExpanded={isExpanded}>
                    <MainMenuWrapper>
                        <MenuBlock hideOnMobile={true}>
                            <Logo to={HeadbotRoute.Home}>
                                <NavLogo />
                                <span>{t("constants.companyName")}</span>
                            </Logo>
                        </MenuBlock>
                        <MenuBlock>
                            <MenuItem>
                                <MenuLink to={HeadbotRoute.Naruto} activeClassName="active">
                                    {t("navigation.anime")}
                                </MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuLink to={HeadbotRoute.Buff} activeClassName="active">
                                    {t("navigation.buff")}
                                </MenuLink>
                            </MenuItem>
                        </MenuBlock>
                    </MainMenuWrapper>
                    <WithoutAuth fallback={<PortalMenuItem />}>
                        <MenuBlock>
                            <MenuItem>
                                <MenuLink to={HeadbotRoute.Login}>{t("login")}</MenuLink>
                            </MenuItem>
                            <MenuItem>
                                <MenuButtonLink to={HeadbotRoute.Signup}>{t("navigation.signup")}</MenuButtonLink>
                            </MenuItem>
                        </MenuBlock>
                    </WithoutAuth>
                </Menu>
            </Container>
        </Root>
    );
};
